<template>
  <a-drawer width="75%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
    <a-tabs v-model="current">
      <a-tab-pane key="1" tab="First" :disabled="firstDisabled">
        <a-card title="Please choice a  product model" style="width: 100%">
          <div style="display:flex;flex-wrap: wrap">
            <div
              style="width: 200px;height: 200px;border-radius:5px;margin-right: 10px;margin-bottom: 10px;display: flex;flex-direction: column;align-items: center;justify-content: space-between;padding: 5px 0"
              v-for="(item,index) in goodsSkuList"
              :style="[{
                border:firstIndex == index ? '1px solid blue' : '1px solid #eee'
              }]"
              @click.stop="changeBrandSkuIndex(index)"
            >
              <img :src="item.url" style="width: 120px;height: 120px;" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </a-card>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Second" :disabled="secondDisabled" force-render>
        <a-form-model-item label="Product Series Name:" >
          <a-input v-model="goodsDetailsData.goodsName" disabled placeholder="" />
        </a-form-model-item>

        <a-form-model-item label="Product Series Introduction:" >
          <a-input v-model="goodsDetailsData.content" disabled placeholder="" />
        </a-form-model-item>
        <a-form-model-item label="Product Model #:" >
          <a-input v-model="oneSingleData.productModel" disabled placeholder="" />
        </a-form-model-item>
        <a-form-model-item label="Variant Code:" >
          <a-input v-model="oneSingleData.variantCode" disabled placeholder="" />
        </a-form-model-item>

        <a-form-model-item label="Marketing Name:" >
          <a-input v-model="oneSingleData.name" disabled placeholder="" />
        </a-form-model-item>

        <a-form-model-item label="Business Type :" >
          <a-input v-model="goodsDetailsData.businessTypeName" disabled placeholder="" />
        </a-form-model-item>

        <a-form-model-item label="Product Type:" >
<!--          <a-input v-model="goodsDetailsData.businessTypeName" placeholder="" />-->
<!--          <a-select v-model="goodsDetailsData.businessTypeName" placeholder="-Select Activation Type-">-->
<!--            <a-select-option :key="0">Button Activated</a-select-option>-->
<!--            <a-select-option :key="1">Inhale Activated</a-select-option>-->
<!--          </a-select>-->

          <a-select v-model="goodsDetailsData.productType" disabled placeholder="-Select Product Type-">
            <a-select-option :key="0">Disposable</a-select-option>
            <a-select-option :key="1">Batteries</a-select-option>
            <a-select-option :key="2">Vaporizer</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Available Compatibility:" >
<!--          <a-input v-model="form.type" disabled placeholder="" />-->
          <a-select v-model="form.type" disabled>
            <a-select-option :key="0">OMNI NFC</a-select-option>
            <a-select-option :key="1">OMNI Hub</a-select-option>
          </a-select>
<!--          <a-radio-group v-model="form.type">-->
<!--            <a-radio :value="0">-->
<!--              OMNI NFC-->
<!--            </a-radio>-->
<!--            <a-radio :value="1">-->
<!--              OMNI Hub-->
<!--            </a-radio>-->
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="Available Tank Size:"  v-if="goodsDetailsData.productType === 0">
          <a-input v-model="form.tankSizeValue" disabled placeholder="" />
<!--          <a-radio-group v-model="form.tankSizeId" @change="changeTankSize($event,form)">-->
<!--            <a-radio :value="ite.id" v-for="(ite, inex) in TankSizeList">-->
<!--              {{ ite.name }}-->
<!--            </a-radio>-->
<!--          </a-radio-group>-->
        </a-form-model-item>

        <a-form-model-item label="Other Option: " >
          <a-input v-model="form.otherOptionValue" disabled placeholder="" />
<!--          <a-select v-model="form.otherOptionId" placeholder="-Select An Option-" style="width: 100%;" @change="changeOtherOption($event,item)">-->
<!--            <a-select-option :key="ite.id" v-for="(ite,idx) in OtherOptionList" >{{ ite.name }}</a-select-option>-->
<!--          </a-select>-->

<!--          <a-radio-group v-model="form.otherOptionId" @change="changeOtherOption($event,form)">-->
<!--            <a-radio :value="ite.id" v-for="(ite, inex) in OtherOptionList">-->
<!--              {{ ite.name }}-->
<!--            </a-radio>-->
<!--          </a-radio-group>-->
        </a-form-model-item>
      </a-tab-pane>

      <a-tab-pane key="3" tab="Last" :disabled="lastDisabled" force-render>
                <a-form-model-item label="Brand" prop="brandId" >
<!--                  <a-input v-model="form.brandId" placeholder="请输入品牌id" />-->
                  <a-select
                    placeholder="-Brand-"
                    style="width: 100%"
                    showSearch
                    v-model="form.brandId"
                    optionFilterProp="title"
                    disabled
                  >
                    <a-select-option v-for="item in brandList" :key="item.id" :value="item.id" :title="item.name">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                  <a-form-model-item label="Your Product Model #:" prop="productModel" >
                    <a-input v-model="form.productModel" placeholder="" :maxLength="100" />
                    <div style="display: flex;justify-content: flex-end">{{ ((form.productModel && form.productModel.length) || 0) }}/100</div>
                  </a-form-model-item>
                  <a-form-model-item label="Your Marketing Name:" prop="name" >
                    <a-input v-model="form.name" placeholder=""  :maxLength="100" />
                    <div style="display: flex;justify-content: flex-end">{{ ((form.name && form.name.length) || 0) }}/100</div>
                  </a-form-model-item>
          <!--        <a-form-model-item label="状态：1开启 0禁用 2保存草稿" prop="status" >-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="Your Product Introduction:" prop="content" >
<!--          <editor v-model="form.content"  :maxLength="3000" />-->
          <a-textarea
            v-model="form.content"
            placeholder=""
            :maxLength="3000"
            auto-size
          />
          <div style="display: flex;justify-content: flex-end">{{ ((form.content && form.content.length) || 0) }}/3000</div>
        </a-form-model-item>
        <a-form-model-item label="Your Product Status:" prop="status" >
          <a-radio-group v-model="form.status">
            <a-radio :value="1">
              Enable
            </a-radio>
            <a-radio :value="0">
              Disable
            </a-radio>
            <a-radio :value="2">
              Draft
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
          <!--        <a-form-model-item label="产品信息" prop="userId" >-->
<!--          <a-input v-model="form.userId" placeholder="请输入产品信息" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="连接方式:0-全方位NFC 1-OMNIHUB(蓝牙)" prop="type" >-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="默认为0 自定义尺寸 否则关联{zb_public_config.id}" prop="tankSizeId" >-->
<!--          <a-input v-model="form.tankSizeId" placeholder="请输入默认为0 自定义尺寸 否则关联{zb_public_config.id}" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="尺寸值" prop="tankSizeValue" >-->
<!--          <a-input v-model="form.tankSizeValue" placeholder="请输入尺寸值" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="其他选项 否则关联{zb_public_config.id}" prop="otherOptionId" >-->
<!--          <a-input v-model="form.otherOptionId" placeholder="请输入其他选项 否则关联{zb_public_config.id}" />-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="其他选项值" prop="otherOptionValue" >-->
<!--          <a-input v-model="form.otherOptionValue" placeholder="请输入其他选项值" />-->
<!--        </a-form-model-item>-->
      </a-tab-pane>
    </a-tabs>

<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="规格id" prop="skuId" >-->
<!--        <a-input v-model="form.skuId" placeholder="请输入规格id" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="品牌id" prop="brandId" >-->
<!--        <a-input v-model="form.brandId" placeholder="请输入品牌id" />-->
<!--      </a-form-model-item>-->

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm" v-if="current == '3'">
            Save
          </a-button>
<!--          <a-button type="primary" :loading="submitLoading" @click="previousStep"  v-if="current == '2'">-->
<!--            The previous-->
<!--          </a-button>-->
          <a-button type="primary" :loading="submitLoading" @click="NextStep"  v-if="current == '2'">
            Next
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSku, addSku, updateSku } from '@/api/cigarette/sku'
import Editor from '@/components/Editor'
import Style from "@/views/cigarette/style";
import {brandAdminListSku, listSku} from "@/api/cigarette/goodSku";
import {getGoods} from "@/api/cigarette/goods";
import {listConfig} from "@/api/cigarette/config";
import {listBrand} from "@/api/cigarette/brand";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Style,
    Editor
  },
  data () {
    return {
      submitLoading: false,
      TankSizeList: [],
      goodsSkuList: [],
      OtherOptionList: [],
      formTitle: '',
      current: "1",
      firstIndex: -1,
      firstDisabled: false,
      secondDisabled: true,
      lastDisabled: true,
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        skuId: null,

        brandId: null,

        productModel: null,

        name: null,

        status: 1,

        content: null,

        userId: null,

        type: null,

        tankSizeId: null,

        tankSizeValue: null,

        otherOptionId: null,

        otherOptionValue: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      goodsDetailsData: {},
      oneSingleData: {},
      brandList: [],
      rules: {
        productModel: [
          { required: true, message: 'Your Product Model cannot be empty', trigger: 'blur' }
        ],
        name: [
          { required: true, message: 'Your Marketing Name cannot be empty', trigger: 'blur' }
        ],
        // type: [
        //   { required: true, message: '连接方式:0-全方位NFC 1-OMNIHUB(蓝牙)不能为空', trigger: 'change' }
        // ],
        // tankSizeValue: [
        //   { required: true, message: '尺寸值不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
    // this.getGoodsSkuList();
    this.getConfigListData();
    this.getBrandListData();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    getBrandListData() {
      listBrand({
        brandStatus:1,
        status:1
      }).then(res => {
        if(res.success) {
          this.brandList=res.data;
        }
      })
    },
    changeTankSize(e,item) {
      console.log(e.target.value)
      if(e.target.value) {
        let shuju = this.TankSizeList.filter(i => i.id == e.target.value);
        if(shuju.length) {
          console.log(shuju)
          this.$set(item, 'tankSizeValue' ,shuju[0].name)
        }
      }
    },
    changeOtherOption(e,item) {
      if(e) {
        let shuju = this.OtherOptionList.filter(i => i.id == e);
        if(shuju.length) {
          this.$set(item, 'otherOptionValue' ,shuju[0].name)
        }
      }
    },
    getConfigListData() {
      listConfig({
        type: 1,
        status: 1
      }).then(res => {
        if (res.success) {
          this.TankSizeList = res.data;
        }
      })

      listConfig({
        type: 2,
        status: 1
      }).then(res => {
        if (res.success) {
          this.OtherOptionList = res.data;
        }
      })
    },
    getGoodsSkuList(brandId) {
      brandAdminListSku({
        brandId:brandId
      }).then(res => {
        if(res.success) {
          this.goodsSkuList = res.data;
        }
      })
    },
    getGoodsDetail(id) {
      getGoods({id:id}).then(res => {
        if(res.success) {
          console.log(res.data);
          this.goodsDetailsData= res.data;

          this.current = "2";
          this.secondDisabled = false;
        }
      })
    },
    changeBrandSkuIndex(index) {
      this.firstIndex = index;
      this.oneSingleData = this.goodsSkuList[index];
      this.form.type = this.oneSingleData.type;
      this.form.skuId = this.oneSingleData.id;
      this.form.productTypeId = this.oneSingleData.productTypeId;
      this.form.productTypeName = this.oneSingleData.productTypeName;
      this.form.activationType = this.oneSingleData.activationType;
      this.form.logo = this.oneSingleData.picture;
      this.form.tankSizeId = this.oneSingleData.tankSizeId;
      this.form.tankSizeValue = this.oneSingleData.tankSizeValue;
      this.form.otherOptionId = this.oneSingleData.otherOptionId;
      this.form.otherOptionValue = this.oneSingleData.otherOptionValue;
      this.getGoodsDetail(this.goodsSkuList[index].goodsId)
      // this.current = "2";
      // this.secondDisabled = false;
    },
    previousStep() {
      this.current = "1";
      this.secondDisabled = true;
      this.lastDisabled = true;
    },
    NextStep() {
      this.current = "3";
      this.lastDisabled = false;
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        skuId: null,
        brandId: null,
        productModel: null,
        name: null,
        status: 1,
        content: null,
        userId: null,
        type: null,
        tankSizeId: null,
        tankSizeValue: null,
        otherOptionId: null,
        otherOptionValue: null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {

      this.getGoodsSkuList(row);
      this.reset()
      this.formType = 1
      this.current = "1";
      this.open = true
      this.form.brandId = row;
      this.formTitle = 'Create SKU'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.getGoodsSkuList(row.brandId);
      this.reset()
      this.formType = 2;
      this.current = "1";
      const id = row ? row.id : ids
      getSku({"id":id}).then(response => {
        this.form = response.data

        setTimeout(() =>{
          if(this.goodsSkuList.length) {

            this.firstIndex= this.goodsSkuList.findIndex(i=>i.id == this.form.skuId)
          }
          this.open = true;
        },700)

        this.formTitle = 'Modify SKU'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSku(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSku(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style >
/deep/ .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 100%!important;
}
</style>
